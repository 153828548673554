export const afVariables = {
  token: {
    colorPrimary: '#ff6542',
    colorPrimaryActive: '#D8562E',
    colorPrimaryBorder: 'rgba(255, 101, 66, 0.2)',
    colorPrimaryHover: '#EB6034',
    colorLink: '#ff6542',
    colorLinkActive: '#D8562E',
    colorLinkHover: '#EB6034'
  },
  components: {
    Button: {
      // colorLink: '#ff6542',
      // colorLinkHover: '#ff8b6b',
      // colorPrimaryHover: '#e0573c'
    },
    Select: {
      controlItemBgActive: 'rgba(255, 101, 66, 0.2)'
    },
    Slider: {
      colorBgElevated: '#ff6542',
      colorPrimaryBorder: '#ff6542',
      colorPrimaryBorderHover: '#ff8b6b'
    }
  }
};
